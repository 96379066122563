//TODO: check constants best practices
export const Colors = {
    white: "#ffffff",
    black: "#181919",
    grayLight: "#f6f6f6",
    gray: "#ededed",
    grayDarker: "#c1c1c1", /* lul */
    grayDark: "#32363f",
    blueLight: "#5aa7d1",
    orange: "#e77300",
}

export const indexBackgrounds = [
    Colors.grayLight,
    Colors.gray,
    Colors.blueLight,
    Colors.grayLight,
    Colors.gray,
];

/* 666 index is fallback for top section */
indexBackgrounds[666] = Colors.grayDark;

export const Fonts = {
    sizes: {
        regular : "1.124em" ,   /* 18pt */
        big: "1.625em" ,        /* 26pt */
        large: "2.125em" ,      /* 34pt */
        huge: "3.125em" ,       /* 68pt */
        insane: "5.125em" ,     /* 82pt */
    },
}

export default Colors;