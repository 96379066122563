import React, {Component} from 'react';
import Header from "./Hero/Header"
import styled from "styled-components";
import PlanBuildRun from "./Hero/PlanBuildRun";
import Claims from "./Hero/Claims";

const Wrapper = styled.div`
  position: relative;
  background: url("images/banner_single.jpg") no-repeat greenyellow; 
  background-size: cover;
  background-position-x: center;
  min-height: 100vh;
  max-height: 1800px;
  padding-top: 1%;
`;

class Hero extends Component {
    render() {
        return (
            <Wrapper>
                <Header />
                <Claims claims={this.props.claims}/>
                <PlanBuildRun steps={this.props.steps}/>
                {this.props.backdrop}
            </Wrapper>
        );
    }
}

export default Hero;
