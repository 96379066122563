import React, {Component} from 'react';
import Hero from "../components/Hero"
import Team from "../components/sections/Team"
import Footer from "../components/sections/Footer"
import Cost from "../components/sections/Cost";
import Webinars from "../components/sections/Webinars";
import Promise from "../components/sections/Promise";
import ScreenWidthOverlay from "../components/ScreenWidthOverlay";


class IndexPage extends Component {
    render() {
        return (
            <React.Fragment>
                <ScreenWidthOverlay/>
                <Hero
                    claims={[
                        {
                            title: `WE DO <span class="highlight">APP DEVELOPMENT</span>`,
                        },
                        {
                            title: `WE DO <span class="highlight">APP DESIGN</span>`,
                        },
                        {
                            title: `WE DO <span class="highlight">APP SERVICES</span>`,
                        },
                        {
                            title: `WE DO <span class="highlight">APP REVIEWS</span>`,
                        },
                        {
                            title: `WE DO <span class="highlight">...YOUR IDEA</span>`,
                        }
                    ]}

                    steps={
                        [
                            {
                                title: "Plan",
                                image: "images/icon_plan.svg",
                                description: "We design the perfect match with you Apple or Android App for your budget and your goals. Our years of experience guarantee your success.",
                            },
                            {
                                title: "Build",
                                image: "images/icon_build.svg",
                                description: "We build your app turnkey without risk and unplanned costs for you. We can and do that.",
                            },
                            {
                                title: "Run",
                                image: "images/icon_run.svg",
                                description: "We run the necessary back-end service in the cloud and take care of the customer care and maintenance of you app.",
                            }
                        ]
                    }

                    backdrop={<div style={{display: 'none'}}>backdrop</div>}
                >

                </Hero>
                <Promise topRight={200} index={0}/>
                <Webinars topLeft={150} index={1}/>
                <Team topRight={150} index={2}/>
                <Cost topLeft={200} index={3}/>
                <Footer topRight={200} index={4}/>
            </React.Fragment>
        );
    }
}

export default IndexPage;
