import React, {Component} from 'react';
import Section from "../Section"
import {Fonts} from '../../common/constants';
import styled from "styled-components";
import {isWidthDown} from "@material-ui/core/withWidth";
import withWidth from "@material-ui/core/withWidth";


//TODO: move styles to separate file (for each component) ?
const Wrapper = styled.div`
      font-size: ${Fonts.sizes.regular};
      line-height: 1.5em;
      margin-top: -1px;

      .flex {
          display: flex;
          margin: 0 auto;
          flex-wrap: wrap;
          align-items: end;
          justify-content: space-around;
          
          .flex-element {
              min-width: 333px;
              width: 19%;
              margin: 0 2% 2% 2%;
          
              h3 {
                  font-weight: bold;
              }
              .logo {
                  width: 60%;
                  margin-bottom: 2%;
              }
              .social-links {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20%;
                  img {
                      width: 15%;
                      height: 15%;
                  }
              }
              .info-row {
                  display: grid;
                  grid-template-columns: 15% 80%;
                  grid-gap: 5%;
                  margin-bottom: 4%;
                  align-items: center;
                  justify-items: flex-start;
                  img {
                      width: 66%;
                  }
                 
              }
              .empty-row {
                  min-height: 8%;
              }     
            
          }
      }
      
      .orange-bottom-text {
          .back-to-top {
              text-align: center;
              .to-top-arrow {
                  width: 6%;
                  vertical-align: middle;
              }
          }
      }
`;

class Footer extends Component {

    handleScrollTop = () => {
        //TODO: maybe add animation?
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Wrapper>
                <Section
                    topLeft={this.props.topLeft}
                    topRight={this.props.topRight}
                    backgrounds={this.props.backgrounds}
                    index={this.props.index}
                >
                    <div className={`flex ${isWidthDown('xs', this.props.width) ? 'mobile-version' : ''}`}>
                        <div className="flex-element">
                            <img alt="logo" className="logo" src="images/logo.png" />
                                <h3>credeo GmbH</h3>
                                <p>Geschäftsführer: Kristijan Cvetkovic</p>
                                <p>Amtsgericht Köln (HRB 62213)</p>
                                <p>Steuernummer: 223/5803/5727</p>
                                <p>USt-IdNr.: DE259874900</p>
                        </div>
                        <div className="flex-element">
                            <div className={'empty-row'}>&nbsp;</div>
                            <div className="social-links">
                                <img alt='facebook' className="icon-image clickable" src="images/icon_facebook.svg"/>
                                <img alt='twitter' className="icon-image clickable" src="images/icon_twitter.svg"/>
                                <img alt='blog' className="icon-image clickable" src="images/icon_blog.svg"/>
                            </div>
                        </div>
                        <div className="flex-element">
                            <div className="info-row">
                                <img alt='phone' className="icon-image" src="images/icon_phone.svg" />
                                <div>
                                    <p>+49 (0) 221 67788791 - 0</p>
                                    <p>+49 (0) 221 67788791 - 9</p>
                                </div>
                            </div>
                            <div className="info-row">
                                <img alt='email' className="icon-image" src="images/icon_mail.svg" />
                                <a href="mailto:someone@examplexxx.com?Subject=Hello%20again" target="_top">info@credeo.de</a>
                            </div>
                            <div className="info-row">
                                <img alt='map' className="icon-image" src="images/icon_location.svg" />
                                    <div>
                                        <p>Am Wassermann 29</p>
                                        <p>50829 Köln</p>
                                    </div>
                            </div>
                            <div className="info-row">
                                <span />
                                <div className="clickable get-directions">get directions</div>
                            </div>
                        </div>
                    </div>
                    <div className="orange-bottom-text flex">
                        <div className='flex-element text-orange'>
                            &copy; credeo GmbH <span>|</span> Datenschutz <span>|</span> AGB
                        </div>
                        <div className="back-to-top clickable flex-element" onClick={this.handleScrollTop}>
                            <img alt='back to top' src="images/icon_arrow.svg" className="to-top-arrow"/> back to the top
                        </div>
                        <div className='flex-element'>&nbsp;</div>
                    </div>
                </Section>
            </Wrapper>
        );
    }
}

export default withWidth() (Footer);
