import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import IndexPage from "./pages/IndexPage";
import AppDevelopmentPage from "./pages/AppDevelopmentPage";

const app = document.getElementById('app');

switch (app.getAttribute("context")) {
    case "index":
        ReactDOM.render(<IndexPage />, app);
        break;
    case "appDevelopment":
        ReactDOM.render(<AppDevelopmentPage />, app);
        break;
    default:
        ReactDOM.render(<IndexPage />, app);
        break;

}
