import styled from "styled-components";
import {Colors, Fonts} from "../../common/constants";
import Section from "../Section";
import React, {Component} from "react";
import WebinarArticle from "../WebinarArticle";
import withWidth, {isWidthDown, isWidthUp} from "@material-ui/core/withWidth";


const Wrapper = styled.div`
      margin-top: -1px;
      
      .flex {
        
        .align-center {
          align-self: center;
        }
        
         div.our-webinars {
          display: block;
          margin: 10% 5%;
          
          span {
            color: ${Colors.black};
            font-size: ${Fonts.sizes.huge};
            font-weight: bold;
          }
        } 
      }
      
     
`;

class Webinars extends Component {
    render() {
        return (
            <Wrapper>
                <Section
                    topLeft={this.props.topLeft}
                    topRight={this.props.topRight}
                    index={this.props.index}
                >
                    <div className={`flex ${isWidthDown('xs', this.props.width) ? 'mobile-version' : ''}`}>
                        <div className={'align-center'}>
                            {isWidthDown('md', this.props.width) &&
                                <div className={'our-webinars'}>
                                    <span>Our<br/>Webinars</span>
                                </div>
                            }
                            <WebinarArticle/>
                        </div>
                        <div>
                            <WebinarArticle/>
                            <WebinarArticle/>
                        </div>
                        <div>
                            {isWidthUp('lg', this.props.width) &&
                                    <div className={'our-webinars'}>
                                        <span>Our<br/>Webinars</span>
                                    </div>
                            }
                            <WebinarArticle/>
                        </div>
                    </div>
                </Section>
            </Wrapper>
        );
    }
}

export default withWidth()(Webinars);