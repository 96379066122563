import React, {Component} from 'react';
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import InfoText from "./Claims/InfoText";
import {Fonts} from "../../common/constants";
import Colors from "../../common/constants";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";

const Wrapper = styled.div`

        display: inline-block;
        position: relative;
        margin: 8%;
        width: 40%;

        @keyframes typing {
          from {
            width: 0;
          }
          to {
            width: 100%;
          }
        }
        
        @keyframes fade-out {
          0% {
            filter: opacity(100%);
          }
          99.9% {
            height: inherit;
          }
          100% {
            filter: opacity(0);
            height: 0;
          }
        }

        .animated-text-wrapper {
            overflow: hidden; /* Ensures the content is not revealed until the animation */
            height: ${props => isWidthDown('lg', props.width) ? 50 : 82}px; 
    
            .animated-text {
              overflow: hidden;
              white-space: nowrap;
              font-size: ${props => isWidthDown('lg', props.width) ? Fonts.sizes.huge : Fonts.sizes.insane}; 
              font-weight: bold;
            }
            
            .highlight {
              color: ${Colors.blueLight};
            }
    
            .animated-text-1 {
              -webkit-animation: typing 1s ease-in, fade-out .5s linear 1.5s 1 normal forwards;
              animation: typing 1s ease-in, fade-out .5s linear 1.5s 1 normal forwards;
            }
    
            .animated-text-2 {
              -webkit-animation: typing 1s ease-in 2s, fade-out .5s linear 3.5s 1 normal forwards;
              animation: typing 1s ease-in 2s, fade-out .5s linear 3.5s 1 normal forwards;
            }
    
            .animated-text-3 {
              -webkit-animation: typing 1s ease-in 4s, fade-out .5s linear 5.5s 1 normal forwards;
              animation: typing 1s ease-in 4s, fade-out .5s linear 5.5s 1 normal forwards;
            }
    
            .animated-text-4 {
              -webkit-animation: typing 1s ease-in 6s, fade-out .5s linear 7.5s 1 normal forwards;
              animation: typing 1s ease-in 6s, fade-out .5s linear 7.5s 1 normal forwards;
            }
    
            .animated-text-5 {
              -webkit-animation: typing 1s ease-in 8s 1 normal forwards;
              animation: typing 1s ease-in 8s 1 normal forwards;
            }
        }
`;

class Claims extends Component {
    render() {
        return (
            <Wrapper width={this.props.width}>
                <div className="animated-text-wrapper">
                    <ul>
                        {this.props.claims.map((claim, index) =>
                            <li key={index} className={`animated-text animated-text-${index+1}`}>{ReactHtmlParser(claim.title)}</li>
                        )}
                    </ul>
                </div>
                <InfoText/>
            </Wrapper>
        );
    }
}

export default withWidth() (Claims);
