import styled from "styled-components";
import {Colors, Fonts} from "../../common/constants";
import Section from "../Section";
import React, {Component} from "react";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";

const Wrapper = styled.div`
      margin-top: -1px;
      
      .flex {
          justify-content: space-between;

          .flex-element {
            margin: 10% 2%;
            position: relative;
          }
          
          .promise-and-deliver {
            width: 33%;
    
            .title {
              font-weight: bold;
              font-size: ${Fonts.sizes.huge};
              color: ${Colors.black};
              margin-bottom: .5em;
            }
            .body, .pagination {
              font-size: ${Fonts.sizes.large};
              color: ${Colors.grayDark};
            }
            .body {
              margin-bottom: 2.5em;
              line-height: 1.2em;
            }
          }
    
          .app-design {
            width: 42%;
            
            .content {
                box-shadow: -6px 0px 44px -7px rgba(0,0,0,0.3);
                padding: 2%;
                background: url("images/pd_blue_stuff.png") no-repeat ${Colors.white};
                background-size: contain;
                min-width: 400px;
                margin-bottom: 5%;
        
                .main-content {
                    margin: 8% 7% 4% 22%;
        
                    .title {
                      font-weight: bold;
                      font-size: ${Fonts.sizes.large};
                      color: ${Colors.black};
                      padding-bottom: 5%;
            
                      .text-light-blue {
                        color: ${Colors.blueLight};
                      }
                    }
            
                    .subtitle {
                      font-size: ${Fonts.sizes.big};
                      color: ${Colors.grayDark};
                      line-height: 1.2em;
                      padding-bottom: 5%;
                    }
            
                    .body {
                      color: ${Colors.black};
                      line-height: 1.5em;
                      margin-bottom: 5%;
                    }
            
                    .button-more {
                      font-weight: bold;
                      font-size: ${Fonts.sizes.regular};
                      text-transform: uppercase;
                      color: ${Colors.orange};
                      border-radius: 50px;
                      border: 2px solid ${Colors.orange};
                      width: fit-content;
                      padding: 7px 25px; 
                      display: block;
                    }
                    .button-more:hover {
                      background: ${Colors.orange};
                      color: ${Colors.white};
                    }
                }
                
                .navigation {
                      position: relative;
                      padding: 0 2%;
                      margin-bottom: 2%;
            
                      .button-right, .button-left {
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                      }
                    
                      .button-right {
                        background: url("images/arrow_right.svg") no-repeat ;
                        float: right;
                      }
                    
                      .button-left {
                        background: url("images/arrow_left_pressed.svg") no-repeat;
                      }
                }
        
                .fake-shadow {
                  width: 100%;
                  height: 97%;
                  background: ${Colors.gray};
                  position: absolute;
                  z-index: -1;
                  left: 0;
                  top: -3%;
                  clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);
                }
            }
            .pagination {
              text-align: center;
            
              .dot {
                display: inline-block;
                height: 22px;
                width: 22px;
                background: ${Colors.grayDarker};
                margin: 0 12px;
                border-radius: 25px;
              }
              .current {
                box-shadow: inset 0 0 0 2pt ${Colors.white}, 0 0 0 2pt ${Colors.orange};
                background: ${Colors.orange};
              }
            }
          } 
      }
`;

class Promise extends Component {
    render() {
        return (
            <Wrapper>
                <Section
                    topLeft={this.props.topLeft}
                    topRight={this.props.topRight}
                    index={this.props.index}
                >
                    <div className={`flex ${isWidthDown('xs', this.props.width) ? 'mobile-version' : ''}`}>
                        <div className={'promise-and-deliver flex-element'}>
                            <div className="title">WE PROMISE <br/>& DELIVER</div>
                            <div className="body">Customers worldwide have been relying on our expertise for over 10
                                years and are booking our experience and skills to achieve their goals.
                            </div>
                        </div>
                        <div className={'app-design flex-element'}>
                            <div className={'content'}>
                                <div className="main-content">
                                    <div className="title"><span className="text-light-blue">app</span> <span>Design</span>
                                    </div>
                                    <div className="subtitle">We tailor your app to fit your budget, timeframe and goals.</div>
                                    <div className="body">We will guide you step by step by step through all the important
                                        questions for your app. You always have control and understand the implications of each
                                        decision. We have been developing and designing mobile applications for over 10 years.
                                        You can trust our expertise.
                                    </div>
                                    <div className="button-more clickable">more</div>
                                </div>
                                <div className="navigation">
                                    <div className="button-left clickable">&nbsp;</div>
                                    <div className="button-right clickable">&nbsp;</div>
                                </div>
                                <div className="fake-shadow">&nbsp;</div>
                            </div>
                            <div className="pagination">
                                <div className="dot current">&nbsp;</div>
                                <div className="dot">&nbsp;</div>
                                <div className="dot">&nbsp;</div>
                                <div className="dot">&nbsp;</div>
                                <div className="dot">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </Section>
            </Wrapper>
        );
    }
}

export default withWidth()(Promise);