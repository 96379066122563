import React, { Component } from 'react';

class AppDevelopmentPage extends Component {
    render() {
        return (
            <React.Fragment>
                AppDevelopmentPage
            </React.Fragment>
        );
    }
}

export default AppDevelopmentPage;
