import styled from "styled-components";
import {Colors, Fonts} from "../../common/constants";
import Section from '../Section';
import React, {Component} from 'react';
import {isWidthDown} from "@material-ui/core/withWidth";
import withWidth from "@material-ui/core/withWidth";


const Wrapper = styled.div`
      text-align: center;
      margin-top: -1px;

      .question {
        font-size: ${Fonts.sizes.large};
        color: ${Colors.black};
        width: 100%;
      }
      .me-button {
        font-weight: bold;
        font-size: ${Fonts.sizes.large};
        color: ${Colors.orange};
        border-radius: 50px;
        border: 2px solid ${Colors.orange};
        width: fit-content;
        padding: 25px 60px;
        display: block;
        margin: 3% auto 0 auto;
      }
      .me-button:hover {
        background: ${Colors.orange};
        color: ${Colors.white};
      }
`;

class Cost extends Component {
    render() {
        return (
            <Wrapper>
                <Section
                    topLeft={this.props.topLeft}
                    topRight={this.props.topRight}
                    index={this.props.index}
                >
                    <div className={`flex ${isWidthDown('xs', this.props.width) ? 'mobile-version' : ''}`}>
                        <div className="question">Interested in how much could cost your App?</div>
                        <div className="me-button clickable">FIND OUT</div>
                    </div>
                </Section>
            </Wrapper>
        );
    }
}

export default withWidth() (Cost);