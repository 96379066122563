import React, {Component} from 'react';
import styled from 'styled-components';
import {Colors, Fonts} from "../common/constants";


const MeArticle = styled.div`
    background: ${Colors.white};
    box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.25);
    padding: 7%;
    max-width: 400px;
    margin: 10% 5%;
    
    img {
          display: block;
          width: 50%;
          margin: 0 auto 7% auto;
          -webkit-box-shadow: 4px 4px 18px 0px rgba(0,0,0,0.47);
          box-shadow: 4px 4px 18px 0px rgba(0,0,0,0.47);
        }

        .title {
          font-size: ${Fonts.sizes.big};
          color: ${Colors.grayDark};
          margin-bottom: 7%;
          text-align: center;
        }

        .body {
          color: ${Colors.grayDark};
          line-height: 1.5em;
        }
`;

class WebinarArticle extends Component {
    render() {
        return (
                <MeArticle className={'flex-element'}>
                    <img alt='video' src="images/icon_video.svg"/>
                    <div className="title">appDevelopment Basics</div>
                    <div className="body">Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley...
                    </div>
                </MeArticle>
        );
    }
}

export default WebinarArticle;