import React, {Component} from 'react';
import styled from "styled-components";
import Colors, {Fonts} from "../../../common/constants";

const Wrapper = styled.div`
  max-width: 250px;
  width: 10%;
  background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 95%,rgba(255,255,255,1) 100%);
  clip-path: polygon(33% 0, 100% 0%, 100% 100%, 0% 100%);
  padding: .3% 5% .3% 20%;
  display: -webkit-box;
  color: ${Colors.orange};
  font-weight: bold;
  font-size: ${Fonts.sizes.big};
  
  span, .menu-button-icon {
     vertical-align: sub; 
  }
  .menu-button-icon {
     margin-left: 60px;
     
     video {
      max-height: 34px;
      max-width: 34px;
     }
  }
`;

class Menu extends Component {
    render() {
        return (
            <Wrapper>
                {/*{this.props.items.map((item, index) => <div key={index}>{item.title}</div>)}*/}
                <span className="clickable">LET'S TALK</span>
                <div className="menu-button-icon">
                    <video className="vertical-center"
                           muted
                           // onMouseOver="this.play()"
                           // onMouseLeave="this.pause(); this.currentTime = 0;"
                    >
                        <source src="images/menu_button.mp4" type="video/mp4" />
                        <source src="images/menu_button.ogv" type="video/ogg" />
                    </video>
                </div>
            </Wrapper>
        );
    }
}

export default Menu;
