import React, {Component} from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 150px;
  width: 10%;
  background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 10%,rgba(255,255,255,0) 100%);
  clip-path: polygon(0 0, 100% 0%, 66% 100%, 0% 100%);
  padding: .3% 20% .3% 5%;
  
  a {
    img {
       width: 100%;
    }
  }
`;

class Logo extends Component {
    render() {
        return (
            <Wrapper>
                <a href="/index.html" className={'clickable'}>
                    <img src="images/logo.png" alt={'logo'} />
                </a>
            </Wrapper>
        );
    }
}

export default Logo;
