import React, {Component} from 'react';
import styled from "styled-components";
import {Fonts} from "../../../common/constants";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";

const Wrapper = styled.div`
  font-size: ${props => isWidthDown('md', props.width) ? Fonts.sizes.regular : Fonts.sizes.big};;
  
  @keyframes slide-in-info-text {
    from {
      left: -100%;
    }
    to {
      left: 10%;
    }
  }
  
  @keyframes blur-out {
    0% {
      filter: blur(5px);
    }
    100% {
      filter: blur(0px);
    }
  }

  position: absolute;
  animation: slide-in-info-text 3s ease-out normal forwards, blur-out 3s;
`;

class InfoText extends Component {
    render() {
        return (
            <Wrapper width={this.props.width}>
                <div className="info-text">We develop your iOS and Android app</div>
            </Wrapper>
        );
    }
}

export default withWidth() (InfoText);
