import React, {Component} from 'react';
import Section from "../Section"
import {Colors, Fonts} from '../../common/constants';
import styled from "styled-components";
import PersonMarker from "./Team/PersonMarker";
import {isWidthDown} from "@material-ui/core/withWidth";
import withWidth from "@material-ui/core/withWidth";


const Wrapper = styled.div`
      margin-top: -1px;
      
      .wrap {
          max-width: 1600px;
          margin: 0 auto;
    
          .title {
            color: ${Colors.black};
            font-size: ${Fonts.sizes.large};
            font-weight: bold;
            text-align: center;
            margin-bottom: 1%;
          }
    
          .body {
            color: ${Colors.grayDark};
            font-size: ${Fonts.sizes.big};
            text-align: center;
            width: 90%;
            margin: 0 auto 4% auto;
          }
    
          .team {
            position: relative;
            width: 80%;
            display: block;
            margin: 0 auto;
    
            
          }
    
          #someone {
            top: 2%;
            left: 31%;
          }
    
          #other {
            top: 2%;
            left: 2%;
          }
    
          img {
            width: 100%;
          }
      }
`;

class Team extends Component {
    render() {
        return (
            <Wrapper>
                <Section
                    topLeft={this.props.topLeft}
                    topRight={this.props.topRight}
                    backgrounds={this.props.backgrounds}
                    index={this.props.index}
                >
                    <div className={`wrap ${isWidthDown('xs', this.props.width) ? 'mobile-version' : ''}`}>
                        <h1 className="title">OUR TEAM</h1>
                        <div className="body">As a team of experts from Cologne, we combine modern technology with processes
                            of the real world to innovative, mobile IT solutions.
                        </div>
                        <div className="team">
                            <PersonMarker personName={'someone'} personPosition={'slacker'} offsetLeft={31} offsetTop={2}/>
                            <PersonMarker personName={'other'} personPosition={'waterboi'} offsetLeft={6} offsetTop={2}/>
                            <img src="images/team.png" alt='team'/>
                        </div>
                    </div>
                </Section>
            </Wrapper>
        );
    }
}

export default withWidth() (Team);
