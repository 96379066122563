import React, {Component} from 'react';
import styled from 'styled-components';
import Colors, {indexBackgrounds} from "../common/constants";
import withWidth from "@material-ui/core/withWidth";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

//TODO: check best practices for styled components vs react components structure organization
const Wrapper = styled.div`
  background : ${props => props.background || "pink"};
  padding-top: ${props => {
      return `${Math.max(props.topLeft, props.topRight)}px`;
  }
};
  clip-path: polygon(0 ${props => props.topLeft}px, 100% ${props => props.topRight}px, 100% 100%, 0 100%);
  
  .mobile-version {
          font-size: .7em;
  }
  
`;

const PreviousSectionBackgroundFiller = styled.div`
  background : ${props => props.background || Colors.white};
  min-height: ${props => Math.max(props.topLeft, props.topRight) || 0};
`;


class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Grid>
                ) : (
                    <PreviousSectionBackgroundFiller
                        background={indexBackgrounds[(this.props.index - 1) >= 0 ? (this.props.index -1): 666]} /* 666 index is fallback for top section */
                        topLeft={this.props.topLeft ? this.props.topLeft : 0}
                        topRight={this.props.topRight ? this.props.topRight : 0}
                    >
                        <Wrapper
                            background={indexBackgrounds[this.props.index]}
                            topLeft={this.props.topLeft ? this.props.topLeft : 0}
                            topRight={this.props.topRight ? this.props.topRight : 0}
                            width={this.props.width}
                        >
                            {this.props.children}
                        </Wrapper>
                    </PreviousSectionBackgroundFiller>
                )}
            </React.Fragment>
        );
    }
}

export default withWidth()(Section);
