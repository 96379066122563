import React, {Component} from 'react';
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import Colors, {Fonts} from "../../common/constants";

const Wrapper = styled.div`
      position: absolute;
      width: 100%;
      bottom: -1px;

      @keyframes pointerTransition {
          0%, 25% {
            clip-path: polygon(23% 40%,100% 0,100% 100%,0 100%,0 0);
          }
          33%, 55% {
            clip-path: polygon(50% 50%,100% 0,100% 100%,0 100%,0 0);
          }
          66%, 100%{
            clip-path: polygon(75% 62%,100% 0,100% 100%,0 100%,0 0);
          }
      }
      
      .gradient-wrapper {
          min-height: 100%;
          min-width: 100%;
          background: linear-gradient(to right, white, black);
          clip-path: polygon(23% 67%,100% 0,100% 100%,0 100%,0 0);
          animation: pointerTransition 10s ease-out forwards;
          padding-top: 15px;
      
          .main-content {
              background: ${Colors.grayDark};
              clip-path: polygon(23% 40%,100% 0,100% 100%,0 100%,0 0);
              animation: pointerTransition 10s ease-out forwards;
              padding: 0 2%;
              
              .messages {
                  display: flex;
                  justify-content: space-between;
                  max-width: 1600px;
                  margin: 0 auto;
                  padding-top: 10%;
            
                  .message {
                      color: ${Colors.white};
                      position: relative;
                      max-width: 17%;
        
                      .message-header {
                        margin-bottom: 10%;
                        font-weight: bold;
                        font-size: ${Fonts.sizes.big};
                        text-transform: uppercase;
            
                        .message-header-icon {
                          margin-left: 10%;
                          vertical-align: middle;
                          display: inline-block;
                          width: 33%;
                        }
                      }
                      .message-body {
                        line-height: 1.2em;
                        word-spacing: 0.1em;
                      }
                  }
              }
          }
      }
`;

class PlanBuildRun extends Component {

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render() {
        return (
            <Wrapper>
                <div className="gradient-wrapper">
                    <div className={'main-content'}>
                        <div className={'messages'}>
                            {this.props.steps.map((claim, index) =>
                                <article key={index} className="message" style={{ 'marginTop': `${index * 4}%`}}>
                                    <div className="message-header">
                                        {ReactHtmlParser(claim.title)}
                                        <img src={claim.image} className="message-header-icon" alt={`claim-${claim.title}}`} />
                                    </div>
                                    <div className="message-body">{ReactHtmlParser(claim.description)}</div>
                                </article>)
                            }
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default PlanBuildRun;
