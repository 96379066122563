import React, {Component} from 'react';
import styled from 'styled-components';
import {Colors} from "../../../common/constants";


const Wrapper = styled.div`
              position: absolute;
              top: ${props => props.offsetTop}%;
              left: ${props => props.offsetLeft}%;
    
              .name {
                font-weight: bold;
                color: ${Colors.black};
                display: block;
              }
              .position {
                font-weight: bold;
                color: ${Colors.white};
                display: block;
                margin: .6% 0;
              }
              .vertical-line {
                height: 7vw;
              }
              .left {
                border-left: 1px solid ${Colors.white};
              }
              .right {
                border-right: 1px solid ${Colors.white};
              }
`;

class PersonMarker extends Component {
    render() {
        return (
                <Wrapper offsetTop={this.props.offsetTop} offsetLeft={this.props.offsetLeft}>
                    <span className="name">{this.props.personName}</span>
                    <span className="position">{this.props.personPosition}</span>
                    <div className="vertical-line left">&nbsp;</div>
                </Wrapper>
        );
    }
}

export default PersonMarker;