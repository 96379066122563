import React, {Component} from 'react';
import Logo from "./Header/Logo";
import Menu from "./Header/Menu";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

class Header extends Component {
    render() {
        return (
            <Wrapper>
                <Logo/>
                <Menu items={[
                    {
                        title: "HOMEPAGE",
                        href: "/index.html"
                    },
                    {
                        title: "SERVICES",
                        href: "/services.html"
                    }
                ]}/>
            </Wrapper>
        );
    }
}

export default Header;
