import React, {Component} from 'react';
import withWidth, {isWidthDown} from "@material-ui/core/withWidth/withWidth";
import styled from "styled-components";


const Wrapper = styled.div`
  position: fixed;
  top: 2em;
  left: 2em;
  color: red;
  background: ${props => { 
      return isWidthDown('lg', props.width) ? 'rgba(176, 75, 80, 0.3)' : 'rgba(76, 75, 180, 0.3)' }
};
  padding: .3em;
  font: 1em bold;
  z-index: 1000;
  opacity: .8;
`;

class ScreenWidthOverlay extends Component {
    render() {
        return (
            <Wrapper width={this.props.width}>
                <p>{`Current width: ${this.props.width}`}</p>
            </Wrapper>
        );
    }
}

export default withWidth()(ScreenWidthOverlay);